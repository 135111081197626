<template>
  <div class="menu-links">
    <SearchModal :isMobile="isMobile" :permission="permission"/>

    <a id="icon-pesquisa" @click="openSearch">
      <Pesquisar/> {{ $t('menu.pesquisar') }}
    </a>

    <router-link id="home" to="/home">
      <Home/> {{ $t('menu.home') }}
    </router-link>

    <router-link id="certificado" v-if="existConfigCert" to="/certificates">
      <Certificado/> {{ $t('menu.certificado') }}
    </router-link>

    <router-link v-if="permission && commentReview"
      id="comentario"
      to="/comentarios"
    >
      <Comment/> {{ $t('menu.comments') }}
    </router-link>

    <router-link v-if="permission || permissionModerator" id="membros" to="/members">
      <Membro/> {{ $t('menu.membros') }}
    </router-link>

    <router-link v-if="permission" id="assinatura" to="/assinatura">
      <Assinatura/> {{ $t('menu.assinatura') }}
    </router-link>

    <router-link v-if="moderationHelper" id="helper" to="/ajuda">
      <Ajuda/> {{ $t('menu.ajuda') }}
    </router-link>

    <Logout/>
  </div>
</template>

<script>
import Logout from "./Logout.vue"
import Ajuda from "@/components/icons/ajuda"
import Assinatura from "@/components/icons/assinatura"
import Certificado from "@/components/icons/certificados"
import Comment from '@/components/icons/comentario.vue';
import Home from "@/components/icons/home"
import Pesquisar from "@/components/icons/pesquisar"
import Membro from "@/components/icons/membros"
import Vue from "vue"
import Cookies from "js-cookie"
import SearchModal from "@/components/SearchModal.vue"

import CertifyService from "@/services/resources/CertifyService"

const serviceCertificate = CertifyService.build()

export default {
  components: {
    Logout,
    Home,
    Ajuda,
    Pesquisar,
    Assinatura,
    Certificado,
    Comment,
    Membro,
    SearchModal
  },
  data() {
    return {
      permissionModerator: false,
      sub_menu: false,
      sub_menu2: false,
      item: null,
      top: 0,
      bottom: 0,
      commentReview: false,
      moderationHelper: true,
      existConfigCert: false,
      permission: false,
      fimMenu: 300,
      fimSubmenu: 0,
      client: {
        width: 0,
      }
    }
  },
  computed: {
    clubIntegration() {
      return this.$store.getters.userClub
    },
    isMobile() {
      return this.client.width <= 576
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
  },
  mounted() {
    this.$root.$on("changePermission", () => {
      this.getPermission()
    })
    this.eventMouse()
    this.getCertificateConfig()
    this.getPermission()
    this.getMetaHelper()
    this.getCommentReview()
    this.getCertificateConfig()
  },
  methods: {
    async getMetaHelper() {
      let resp = await this.$store.dispatch("fetchMetas", ["helper_data"])
      var data = resp.helper_data
      if (data === "off") {
        this.moderationHelper = false
      } else {
        this.moderationHelper = true
      }
    },
    async getCommentReview() {
      let resp = await this.$store.dispatch("fetchMetas", ["comment_review"])
      var data = resp.comment_review
      if (data === "off") {
        this.commentReview = false
      } else {
        this.commentReview = true
      }
    },
    onOpen() {
      this.eventMouse();
      this.getCertificateConfig();
      this.getPermission();
      this.getMetaHelper();
      this.getCommentReview();
      this.getCertificateConfig();
    },
    getCertificateConfig() {
      serviceCertificate
        .read("check-exist")
        .then(resp => { this.existConfigCert = resp })
        .catch(err => console.error(err))
    },
    handleResize() {
      this.client.width = window.innerWidth
    },
    getPermission() {
      let permission = Cookies.get("access_level")
      this.permissionModerator = Cookies.get("access_level") === 'student_moderator'
      let typeView = Cookies.get("type_student")
      if (permission === "admin" || permission === "owner") {
        if (typeView === 1 || typeView === "1") {
          this.permission = false
          return
        }
        this.permission = true
      } else {
        this.permission = false
      }
    },
    openSearch() {
      this.$root.$emit("openSearch");
    },
    eventLoginClub() {
      Vue.swal({
        title: this.$t('menu.swt2_1'),
        text: this.$t('menu.swt2_2'),
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.$t('menu.swt2_3'),
        cancelButtonText: this.$t('menu.swt2_4'),
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("clubLogin")
        }
        if (result.dismiss == "cancel") {
          this.$router.push("/produtos")
        }
      })
    },
    subMenu01(e) {
      this.item = e.currentTarget;
      this.top = this.item.offsetTop;
      const submenu = document.querySelector("#sub_01");
      submenu.style = `top: ${this.top}px`;
      this.bottom = this.top + submenu.offsetHeight;
      this.fimSubmenu = this.fimMenu + submenu.offsetLeft - 71;

      this.sub_menu = !this.sub_menu;
      this.sub_menu2 = false;
    },

    eventMouse() {
      window.addEventListener("mousemove", (e) => {
        if (
          e.clientX > this.fimSubmenu ||
          (e.clientX > this.fimMenu && e.clientY < this.top) ||
          (e.clientX > this.fimMenu && e.clientY > this.bottom)
        ) {
          this.sub_menu = false;
          this.sub_menu2 = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.menu-links {
  display: flex;
  flex-direction: column;
  height: 60vh;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0 !important;
}
.menu-links a {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 200px;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
  /* padding-left: 40px; */
  color: var(--fontcolor);
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 15px;
  margin: 0 auto;
}
.menu-links a:hover {
  text-decoration: none;
  color: var(--maincolor);
}
.menu-links a svg {
  /* filter: invert(50%); */
  /* deixar o icone preto */
  /* filter: brightness(0) invert(0);; */
  transition: all 0.3s ease;
  margin: 0;
  margin-right: 40px;
  margin-left: -3px;
}
.menu-links a.router-link-exact-active svg path,
.menu-links a:hover svg path {
  stroke: var(--maincolor) !important;
}
.menu-links a.router-link-exact-active,
.menu-links a:hover {
  color: var(--maincolor) !important;
}

.menu-links a:hover svg {
  path {
    stroke: var(--maincolor) !important;
  }
}
/* .menu-links a.router-link-exact-active::before {
  content: "";
  width: 5px;
  height: 60px;
  background: var( --maincolor);
  border-radius: 0px 5px 5px 0px;
  display: block;
  position: absolute;
  left: -34px;
  transition: all 0.3s ease;
} */
.menu-links a.router-link-exact-active::before {
  content: "";
  width: 7px;
  height: 7px;
  background: var(--maincolor);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  transition: all 0.3s ease;
}
img.arrow {
  /* margin: 0 !important; */
  margin-left: 10px !important;
}
/* Sub menu */
.sub-menu {
  width: 230px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #fff;
  padding: 25px;
  position: fixed;
  left: 200px;
  /* top: 200px; */
  z-index: 55555 !important;
  animation: showLeft 0.3s forwards;
}
#sub_02 {
  width: 280px;
}
.sub-menu li a {
  font-weight: 600;
  color: #333;
  font-size: 14px;
  padding: 10px;

  /* background: silver; */
}
.sub-menu li a.router-link-exact-active::before {
  /* display: block;
  position: absolute; */
  left: -15px;
}
.sub-menu li a:hover {
  text-decoration: none;
  color: #000;
}
.sub-menu li a + a {
  margin-top: 20px;
}
.sub-menu li a img {
  margin-right: 25px;
}
// .Menu:hover .menu-links a {
//   color: #333;
// }

/* --- */
.sub-menu2 {
  top: 300px;
}
.rotateArrow {
  transform: rotate(-90deg);
}

.logo-club {
  width: 115px;
  margin-left: -3px !important;
}
</style>